<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      title="编辑"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="模版名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入短信模版名称' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="模版内容" extra="*注：模板中可包含多个变量参数，变量参数用{数字}表示，且必须从1开始连续编号，如{1}、{2}、{3}">
          <a-textarea
            v-decorator="['content', {
              rules: [
                { required: true, message: '请输入短信模版内容' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            placeholder="注：模板中可包含多个变量参数，变量参数用{数字}表示，且必须从1开始连续编号，如{1}、{2}、{3}"
            :auto-size="{ minRows: 5, maxRows: 8 }"
          />
        </a-form-item>

        <a-form-item label="说明">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { message: '请输入说明' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findSmsTemplateForm, updateSmsTemplate } from '@/api/sms_template'
export default {
  name: 'EditSmsTemplate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'new_sms_template' }),
      submitting: false,
      carriersCount: 0
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    verifyCarrierType(rule, value, callback) {
      if (value && this.carriersCount > 0) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('已有运营商账户使用此模版，无法修改此参数')
      } else {
        callback()
      }
    },

    fetchFormData() {
      findSmsTemplateForm(this.id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({
            name: res.data.name,
            content: res.data.content,
            remark: res.data.remark
          })
          this.carriersCount = res.data.carriers_count
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateSmsTemplate(this.id, values).then((res) => {
            if (res.code === 0) {
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
